<template>
  <div class="signin-wrapper">
    <div class="signin-form">
      <div class="signin-form-label">{{ $t('manager-profile.Войти')}}</div>
      <div class="signin-form-input-label" for="login">{{ $t('manager-profile.Логин')}}</div>
      <el-input v-model="login"  type="text" name="login" :placeholder="$t('manager-profile.Введите_Логин')"/><br>
      <div class="signin-form-input-label" for="login">{{ $t('manager-profile.Пароль')}}</div>
      <el-input type="password" v-on:keyup.enter="auth"  v-model="password"   name="password"  :placeholder="$t('manager-profile.Введите_Пароль')"/><br>
      <button @click="auth"  class="signin-form-button">{{ $t('manager-profile.Войти')}}</button>
    </div>
    <div class="qabilet-card">
      <img style="margin: auto" width="284px" height="102px" src="/images/Qabiletogo.svg">
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      login:"",
      password:""
    }
  },

mounted() {
    if(localStorage.getItem('role')=='center-coach'){
      this.$router.push('/profile-manager')
    }
},

  methods: {
    auth(){
      this.$http.post(window.API_ROOT + '/api/center-manager/login',{
        "email": this.login,
        "password":this.password
      })
          .then((res) => {
            console.log(res)
            localStorage.setItem('token', res.body.success.token)
            localStorage.setItem('role', res.body.success.role)
            localStorage.setItem('user',JSON.stringify(res.body.success.user))
            this.$router.push('/profile-manager')
          })
    }
  },
}
</script>
<style lang="css">
.qabilet-card{
  display: flex;
  justify-content: center;
  margin: auto 0;
  width: 492px;
  height: 364px;
  left: 965px;
  top: 352px;

  background: #FFFFFF;
  box-shadow: 0px 7px 23px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.signin-wrapper {
  justify-content: center;
  display: flex;
  margin: auto;
}

.signin-form-label {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #343F68;
  width: 100%;
  margin-bottom: 28px;

}

.signin-form-input {
  padding: 15px;
  height: 50px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-sizing: border-box;
  border-radius: 15px;
}

.signin-form-button {
  font-size: 15px;
  color: white;
  margin-top: 44px;
  border-radius: 12px;
  background: #04C2F4;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: none;
}

.signin-form-input-label {
  margin-top: 28px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #343F68;

}

.signin-form {
  z-index: 2;
  padding: 52px;
  background: #FFFFFF;
  box-shadow: 0px 7px 23px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin: auto 0;
  width: 453px;
  height: 468px;
  width: 453px;
  height: 468px;
}
</style>